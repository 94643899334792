.page-main {
  width: 100%;
}

.intro {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 650px;
  background-color: #fbefe5;
  background-image: url('./assets/img/intro.webp');
  background-position: 60% 0;
  background-repeat: no-repeat;
  border-bottom: 2px solid #363636;
  border-top: 2px solid #363636;

  &::before {
    position: absolute;
    @include size(100%, 100%);
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.6;
    content: ' ';
    background-size: 100%;
  }
  @media (max-width: $desktop-width) {
    border: none;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./assets/img/intro-mobile.webp');
    background-size: 100%;
  }
}

.intro__wrapper {
  @include make-center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 3;
}

.title-container {
  width: 70%;
  @media (max-width: $desktop-width) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.intro__title {
  margin-top: 100px;
  margin-bottom: 0;
  font-display: swap;
  color: $bisque-color;
  font-size: 60px;
  font-weight: 700;
  font-family: $PT-Sans-Caption;

  @media (max-width: $desktop-width) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
  }
}

.intro__text {
  font-size: 30px;
  color: $bisque-color;
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-weight: 400;

  @media (max-width: $desktop-width) {
    margin: 0 20px 20px;
    text-align: justify;
    font-size: 13px;
  }
}

.intro__button {
  padding: 30px 20px;
  margin-bottom: 100px;
  align-self: flex-end;
  font-family: $PT-Sans-Caption;
  font-size: 20px;
  border: none;
  border-radius: 0px 45px;
  background-color: $bisque-color;
  &:hover {
    background-color: rgb(187, 173, 160);
  }

  @media (max-width: $desktop-width) {
    padding: 10px;
    border-radius: 30px;
    font-size: 13px;
    align-self: center;
    font-size: 13px;
    margin: 0 20px 10px;
  }
}
