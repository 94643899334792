.modal-window {
  z-index: 100;
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  padding: 20px 50px;
  margin-top: -310px;
  margin-left: -250px;
  width: 500px;
  background-color: #363636;
  border: 5px solid #d8d7d7;
  border-radius: 30px;
  @include slideTop();

  @media (max-width: $desktop-width) {
    margin-top: -260px;
    margin-left: 0px;
    top: 50%;
    width: 100%;
    left: 0;
    padding: 15px 20px;
  }
}

.modal-window__tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #363636;
}

.modal-window__title {
  font-family: $PT-Sans-Caption;
  color: $bisque-color;
  font-display: swap;
  font-weight: 700;
  font-size: 25px;
  margin: 0;
  margin-bottom: 10px;
  @media (max-width: $desktop-width) {
    font-size: 18px;
  }
}

%pseudo-block {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  @include size(25px, 2px);
  background-color: $bisque-color;
}

.modal-window__btn-close {
  position: relative;
  @include size(30px, 30px);
  border: none;
  background-color: transparent;
  opacity: 0.5;
  transform: translateY(-3px);
  &:hover {
    opacity: 1;
  }
  &::before {
    @extend %pseudo-block;
  }

  &::after {
    @extend %pseudo-block;
    transform: rotate(-45deg);
  }
}

.modal-overlay {
  background-color: #151313;
}

.modal-window__form {
  display: flex;
  flex-direction: column;
}

.modal-window__input-wrapper {
  display: flex;
  flex-direction: column;
  height: 60px;
  margin-bottom: 10px;
}

.modal__window-subtitle {
  font-family: $PT-Sans-Caption;
  font-display: swap;
  color: $bisque-color;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;

  @media (max-width: $desktop-width) {
    font-size: 15px;
  }

  &--services {
    @extend .modal__window-subtitle;
    margin: 0;
    margin-bottom: 10px;
  }
  &--checkbox {
    @extend .modal__window-subtitle;
    color: #fff;
    margin: 0;
    font-size: 15px;
  }
}

.modal-window__input {
  border: 1px solid #363636;
  margin: 0;
  padding: 5px 10px;
  box-sizing: border-box;
  font-display: swap;
  background-color: #d8d7d7;
  border-radius: 10px;
  font-family: $PT-Sans-Caption;
  font-size: 14px;
  @media (max-width: $desktop-width) {
    padding: 5px 10px;
  }

  &:focus {
    outline: 4px solid #488047;
    border: none;
  }
}
.input-overlay {
  background-color: #5d5d5d;
}

.modal__window__button-submit {
  position: relative;
  top: 0;
  padding: 10px 20px;
  background-color: #488047;
  color: #d8d7d7;
  font-family: $PT-Sans-Caption;
  font-size: 18px;
  border: none;
  margin-top: 20px;
  border-radius: 10px;
  transition: background-color 0.5s ease-in 0;
  box-shadow: 0 3px 0 #000;

  &:hover {
    background-color: #365c36;
    transition: background-color 0.3s linear;
  }

  &:active {
    top: 3px;
    box-shadow: none;
  }

  &:disabled {
    background-color: #5a6359;
  }

  @media (max-width: $desktop-width) {
    padding: 5px 10px;
    font-size: 15px;
  }
}

.error {
  box-shadow: 0 0 15px rgb(255, 40, 40);
}

.modal-window__checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.modal-window__input--checkbox {
  @include size(20px, 20px);
  margin: 0;
  margin-right: 10px;
  border: none;
}

.checkbox-label {
  position: relative;
  display: inline-flex;
  border-radius: 10px;
  align-items: center;
  font-display: swap;
  color: #fff;
  font-size: 13px;
  font-family: $PT-Sans-Caption;
}

.modal-window__services-wrapper {
  display: flex;
  height: 20px;
  margin-bottom: 5px;
  align-items: center;
}

.modal-window__time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.date-subtitle {
  width: 100%;
}

.modal-window__time-input-wrapper {
  display: flex;
  width: 220px;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: $desktop-width) {
    width: 230px;
  }
}

.success-message {
  @include msgInModal(#548753, $background-color);
}

.error-message {
  @include msgInModal($white-pink-color, rgb(152, 9, 9));
}
