.page-description {
  background-color: $background-color;
  padding-top: 100px;
  @media (max-width: $desktop-width) {
    width: 100%;
    height: auto;
    padding: 0;
  }
}

.description__wrapper {
  @include make-center;
  padding: 50px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: $bisque-color;
  border-radius: 30px;
  @media (max-width: $desktop-width) {
    background-color: $background-color;
    border: none;
    padding: 0;
    flex-direction: column;
    margin: 0 20px;
  }
}

.description__img {
  position: relative;
  z-index: 1;
  border-radius: 30px;
  z-index: 1;
  box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: $desktop-width) {
    display: none;
  }
}

.description__text {
  width: 600px;
  text-align: justify;
  line-height: 30px;
  align-self: center;
  font-display: swap;
  font-size: 20px;
  font-family: $PT-Sans-Caption;
  color: #363636;
  margin: 0;
  margin-left: 50px;
  @media (max-width: $desktop-width) {
    margin-left: 0;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    text-align: start;
    font-size: 15px;
    line-height: 18px;
    color: $bisque-color;
  }
}

.description__img-overlay {
  @include size(auto, auto);
  position: relative;
  &::after {
    position: absolute;
    content: ' ';
    left: 0;
    border-radius: 30px;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #363636;
    opacity: 0.5;
    @include size(500px, 300px);
    z-index: 2;
    @media (max-width: $desktop-width) {
      display: none;
    }
  }
}

.description__image-subtitle {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  font-family: $PT-Sans-Caption;
  font-display: swap;
  color: #363636;
  @media (max-width: $desktop-width) {
    display: none;
  }
}
