.page-header {
  background-color: $background-color;
  @media (max-width: $desktop-width) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

.header__wrapper {
  @include make-center;
  min-height: 60px;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $desktop-width) {
    flex-direction: column;
    height: 30px;
  }
}

.header__img {
  height: min-content;
}

.header__logo {
  height: min-content;
  @media (max-width: $desktop-width) {
    margin-left: 20px;
  }
}

.header__button {
  cursor: pointer;
  display: none;
  position: relative;
  margin-right: 20px;
  width: 30px;
  height: 20px;
  background-color: transparent;
  border-bottom: 2px solid $bisque-color;
  border-top: 2px solid $bisque-color;
  border-left: none;
  border-right: none;

  &::before {
    display: block;
    margin-right: 0;
    box-sizing: border-box;
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    left: 0;
    top: 49%;
    background-color: $bisque-color;
  }
  @media (max-width: $desktop-width) {
    display: inline-block;
  }
}

.header__left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: $desktop-width) {
    min-height: 50px;
  }
}

.header__nav {
  display: flex;
  flex-direction: row;
  @media (max-width: $desktop-width) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.header__link {
  color: $bisque-color;
  text-decoration: none;
  font-display: swap;
  font-size: 20px;
  margin-right: 20px;
  font-family: $PT-Sans-Caption;
  padding: 5px;
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $white-pink-color;
  }

  &:focus {
    outline: 1px solid $white-pink-color;
  }

  &--active {
    @extend .header__link;
    border-bottom: 2px solid $white-pink-color;
  }

  @media (max-width: $desktop-width) {
    width: 100%;
    display: flex;
    margin-right: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    color: #363636;
    border-bottom: 1px solid #ffffff;
    min-height: 30px;
    background-color: #d9d9d9;
    &--active {
      background-color: #f5f5f5;
      border-bottom: none;
    }
  }
}
