.page-advantages {
  position: relative;
  background-color: $background-color;
  width: 100%;
  @media (max-width: $desktop-width) {
    background-color: $bisque-color;
  }
}

.advatages-wrapper {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
}

.advantages__title {
  @include section-title($bisque-color);
  @media (max-width: $desktop-width) {
    color: $background-color;
  }
}

.advantages__list {
  @include list;
  position: relative;
  margin: 70px 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content min-content;
  column-gap: 5%;
  row-gap: 70px;

  @media (max-width: $desktop-width) {
    margin: 40px 20px 20px;
    display: flex;
    flex-direction: column;
  }
}

.advantages__item {
  min-height: 300px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  border-radius: 45px;
  box-shadow: 10px 10px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: $bisque-color;

  @media (max-width: $desktop-width) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 100px;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: none;
  }

  &::before {
    position: absolute;
    top: 0px;
    left: 20px;
    content: '';
    @include size(125px, 125px);
    background-image: url('./assets/img/advantages-svg/quality.svg');
    background-repeat: no-repeat;
    transform: translate(10px, 30px);
    @media (max-width: $desktop-width) {
      top: 2%;
      left: 0;
      transform: translate(0);
    }
  }

  &:nth-child(2) {
    &::before {
      background-image: url('./assets/img/advantages-svg/experience.svg');
      background-size: 125px 125px;
    }
  }
  &:nth-child(3) {
    &::before {
      background-image: url('./assets/img/advantages-svg/one-place.svg');
      background-size: 125px 125px;
    }
  }
  &:nth-child(4) {
    &::before {
      background-image: url('./assets/img/advantages-svg/buses.svg');
      background-size: 125px 125px;
    }
  }
  &:nth-child(5) {
    &::before {
      background-image: url('./assets/img/advantages-svg/truck.svg');
      background-size: 125px 125px;
    }
  }
  &:nth-child(6) {
    &::before {
      background-image: url('./assets/img/advantages-svg/control.svg');
      background-size: 125px 125px;
    }
  }
}

.advantages__item-text {
  width: 280px;
  font-family: $PT-Sans-Caption;
  font-size: 20px;
  font-weight: 400;
  font-display: swap;
  text-align: center;
  line-height: 30px;
  display: flex;
  transform: translateY(150px);
  @media (max-width: $desktop-width) {
    font-size: 15px;
    width: 50%;
    transform: translateY(0);
    text-align: start;
  }
}
