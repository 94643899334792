.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @include opacity();
}
