.contacts-page {
  width: 100%;
  background-color: $bisque-color;
  display: flex;
  flex-direction: column;
}

.contacts-page__title {
  @include section-title($black-grey-color);
  margin-top: 50px;
  @media (max-width: $desktop-width) {
    width: min-content;
    text-align: center;
  }
}

.contacts-page__wrapper {
  @include make-center;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  @media (max-width: $desktop-width) {
    margin-bottom: 0;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
  }
}

.contacts-page__info {
  width: 50%;
  @media (max-width: $desktop-width) {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.contacts-page__main-info {
  width: 95%;
  margin: 50px 0;
  box-sizing: border-box;
  background-color: $brown-color;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 10px 20px;

  @media (max-width: $desktop-width) {
    width: 100%;
    background-color: transparent;
    margin-top: 20px;
    padding: 0;
  }
}

.contacts-page__text {
  width: 100%;
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-size: 16px;
  color: #000;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $desktop-width) {
    font-size: 15px;
  }
}

.contacts-page__personal {
  @include list;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;

  @media (max-width: $desktop-width) {
    display: flex;
    flex-direction: column;
    margin: 40px 20px;
  }
}

.contacts-page__personal-item {
  position: relative;
  width: 275px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  background-color: $brown-color;
  border-radius: 20px 20px 0 0;

  @media (max-width: $desktop-width) {
    width: 100%;
    min-height: 100px;
    border-radius: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: ' ';
    width: 0;
    height: 0;
    box-sizing: border-box;
    border-left: 138px solid transparent;
    border-right: 138px solid transparent;
    border-bottom: 50px solid $bisque-color;
    @media (max-width: $desktop-width) {
      display: none;
    }
  }
}

.contacts-page__personal-title {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-display: swap;
  font-size: 18px;
  font-family: $PT-Sans-Caption;
  font-weight: 400;
  margin: 0;
  text-align: center;
  line-height: 25px;
  padding-bottom: 5px;
  border-bottom: 3px solid $background-color;
}

.contacts-page__personal-text {
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-weight: 400;
  font-size: 15px;
  color: #000;
  margin-top: 15px;
}

.contacts-page__map {
  @include size(50%, 530px);
  margin-top: 50px;
  border-radius: 30px;
  @media (max-width: $desktop-width) {
    height: 350px;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.contacts-page__req {
  margin: 10px 0;
  font-family: $PT-Sans-Caption;
  font-display: swap;
  text-align: center;
}

.text-underlined {
  text-decoration: underline;
}

.contacts-page__top {
  display: flex;
  @media (max-width: $desktop-width) {
    flex-direction: column;
  }
}
