body {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
}

#root {
  position: relative;
  height: 100%;
}
