.contacts {
  position: relative;
  background: $white-pink-color;
  @media (max-width: $desktop-width) {
    background-color: $black-grey-color;
  }
}

.contacts__wrapper {
  @include make-center;
  flex-direction: column;
}

.contacts__title {
  @include section-title($background-color);
  margin-bottom: 50px;
  @media (max-width: $desktop-width) {
    color: $bisque-color;
    border-bottom: 1px solid $bisque-color;
  }
}

.contacts__container {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 500px;
  background-color: #ffffff;
  margin-bottom: 100px;
  box-shadow: 5px 5px 4px 0px $background-color;

  @media (max-width: $desktop-width) {
    margin-top: 20px;
    margin-bottom: 0;
    flex-direction: column;
    box-shadow: none;
  }
}

.contacts__address {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 30px;
  width: 50%;
  background-color: $black-grey-color;
  color: #fff;
  @media (max-width: $desktop-width) {
    padding: 0 20px 20px;
    width: auto;
  }
}

.contacts__company {
  margin: 0;
  margin-bottom: 30px;
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-weight: 700;
  font-size: 30px;

  @media (max-width: $desktop-width) {
    margin: 0;
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.contacts__address-text {
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;

  @media (max-width: $desktop-width) {
    font-size: 15px;
  }
}

.telephone {
  font-family: $PT-Sans-Caption;
  font-size: 25px;
  margin-bottom: 0;
  @media (max-width: $desktop-width) {
    font-size: 18px;
  }
}

.contacts__address-subtitle {
  font-family: $PT-Sans-Caption;
  font-size: 25px;
  font-weight: 700;
  font-display: swap;
  color: #ffde88;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
  @media (max-width: $desktop-width) {
    font-size: 18px;
  }
}

.contacts__time {
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-size: 20px;
  font-weight: 700;

  @media (max-width: $desktop-width) {
    font-size: 15px;
  }
}

.contacts__map {
  width: 50%;
  @media (max-width: $desktop-width) {
    width: 100%;
    height: 400px;
  }
}

.contacts__link {
  text-decoration: none;
  font-display: swap;
  font-family: $PT-Sans-Caption;
  margin-bottom: 10px;

  &:nth-child(2) {
    margin-bottom: 0;
  }
}

.contacts__link-wrapper {
  display: flex;
  height: auto;
  flex-direction: column;
  margin-bottom: 20px;
}
