@mixin make-center {
  position: relative;
  margin: 0 100px;
  display: flex;
  @media (max-width: 1152px) {
    margin: 0;
  }
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin flexSize($width, $height) {
  width: $width;
  min-height: $height;
}

@mixin list() {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@mixin section-title($color) {
  position: relative;
  margin: 50px auto 0;
  display: flex;
  color: $color;
  font-size: 40px;
  font-family: $PT-Sans-Caption;
  font-display: swap;
  font-weight: bold;
  @media (max-width: $desktop-width) {
    margin: 30px auto 0;
    padding-bottom: 5px;
    border-bottom: 2px solid ($color);
    font-size: 25px;
  }
  &::before {
    position: absolute;
    content: '';
    top: 20px;
    left: -130px;
    width: 100px;
    height: 20px;
    border-top: 1px solid $color;
    border-bottom: 1px solid $color;
    @media (max-width: $desktop-width) {
      display: none;
    }
  }
  &::after {
    position: absolute;
    content: '';
    top: 20px;
    right: -130px;
    width: 100px;
    height: 20px;
    border-top: 1px solid $color;
    border-bottom: 1px solid $color;
    @media (max-width: $desktop-width) {
      display: none;
    }
  }
}

@mixin msgInModal($bg-color, $color) {
  margin-top: 10px;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: justify;
  font-family: $PT-Sans-Caption;
  font-size: 15px;
  color: $color;
  line-height: 17px;
  background-color: $bg-color;
  opacity: 0;
  animation: opacityAni 1s forwards;
}

@mixin sliderButton() {
  color: $bisque-color !important;
  &:hover {
    color: $background-color !important;
  }
  &::after {
    font-size: 150px !important;

    @media (max-width: $desktop-width) {
      font-size: 50px !important;
    }
  }
}

@mixin opacity() {
  opacity: 0;
  animation: opacityAni 1s forwards;
}

@mixin slideTop() {
  transform: translateY(-150%);
  animation: modal-down 1s forwards;
}

@mixin new() {
  position: absolute;
  overflow: hidden;
  top: 15px;
  left: 0;
  width: 0;
  text-align: right;
  white-space: nowrap;
  animation: ani 1s linear forwards;
}
