.catalog {
  height: auto;
  background: $catalog-background;
}

.catalog__wrapper {
  @include make-center;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
}

.catalog__title {
  @include section-title($bisque-color);
  margin-bottom: 70px;
}

.catalog__services-container {
  @include list;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 70px;
  row-gap: 50px;
  margin-bottom: 70px;
  @media (max-width: $desktop-width) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 20px;
  }
  .toggle__button {
    @media (max-width: $desktop-width) {
      display: none;
    }
  }
}

.catalog__card {
  @include flexSize(100%, 400px);
  display: grid;
  grid-template-rows: 130px auto 1fr auto;
  margin-right: 30px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: $bisque-color;
  border-radius: 45px;
  transform: translateY(-150%);
  animation: opacityCards 1.5s forwards;

  @media (max-width: $desktop-width) {
    margin-bottom: 10px;
    margin-right: 0;
    transform: translateY(-200%);
  }
}

.card__image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__title {
  width: 100%;

  margin: 0;
  margin-top: 20px;
  border-bottom: 1px solid #363636;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #363636;
  font-family: $PT-Sans-Caption;
}

.card__description {
  display: flex;
  align-self: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card__description-text {
  width: 100%;
  text-align: justify;
  font-display: swap;
  font-family: $PT-Sans-Caption;
  font-size: 15px;
  font-weight: 400;
}

.card__button {
  padding: 10px 20px;
  font-family: $PT-Sans-Caption;
  font-size: 15px;
  background-color: #bc9d80bf;
  color: #363636;
  border: none;
  border-radius: 30px;

  &:hover {
    background-color: $white-chocolate;
  }
}

.card__button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
