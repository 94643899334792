.loader {
  position: absolute;
  top: 47%;
  left: 50%;
  z-index: 150;
  content: '';
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  margin-top: -28px;
  margin-left: -28px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-app {
  @extend .loader;
  margin-top: 25%;
  left: 50%;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #ff3d00;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
