$desktop-width: 1152px;
$mobile-width: 320px;
$PT-Sans-Caption: "PT Sans Caption", sans-serif;
$bisque-color: #e1c9b3;
$background-color: #2b2a2a;
$white-chocolate: #867d75;
$brown-color: rgb(186, 158, 132);
$white-pink-color: #d7a7a7;
$catalog-background: linear-gradient(225deg, #867d75 39.09%, #d7a7a7 78.58%);
$black-grey-color: #363636;
